import React from "react"
import { graphql } from "gatsby"
import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"

import Jul from "../components/jul/Jul"
import Nyar from "../components/jul/Nyar"

function JulPage() {
  return (
    <PageLayout>
      <SEO
        keywords={[`Julbord`, `Nyårsmeny`, `Julmat`, `Köttbullar`]}
        title="Jul &amp; Nyår"
      />
      <section
        id="jul-meny"
        className="flex flex-col lg:flex-row w-full lg:w-full justify-center align-top items-start max-w-6xl mx-auto px-3 lg:space-x-8"
      >
        <Jul />
        <Nyar />
      </section>
    </PageLayout>
  )
}

export default JulPage
